import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {from, Observable} from 'rxjs';
import {ConfirmationDialogComponent} from '../views/front/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    message: string = "Etes-vous sûre de vouloir annuler l'opération ?",
    title: string = "Veuillez confirmer",
    btnOkText: string = 'OUI',
    btnCancelText: string = 'NON',
    dialogSize: 'sm'|'lg' = 'sm'): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return from(modalRef.result);
  }

}
