<nav>
  <div class="nav-top ">
    <div class="top-content">


      <div class="flex-center">
          <a href="https://wa.me/+237655767535" target="_blank" >
            <img src="assets/new/newdesign/images/WhatsApp.png" style="width: 25px;" />
            <span style="color: #fff;" > +237 655 767 535</span>
          </a>
      </div>


      <div >
        <a class="flex-center text-white" target="_blank" href="mailto:commercial@telemex.com">
        <img src="assets/new/newdesign/images/Iconmaterial-email.svg" style="width: 20px; display: block;">
        <span>commercial@telemex.com</span>
       </a>
      </div>


    </div>
  </div>

  <div class="nav-bottom">
    <a href="{{telemexLink}}/index.php">
    <img src="../../../../../assets/new/newdesign/images/logo.png" style="width: 65%;" alt="Logo">
  </a>
    <div style="display: flex; gap: 20px; align-items: center;" class="menu-large ">
      <a class="nav-link "  href="{{telemexLink}}/chargement-new.php">envoyer une colis</a>
      <a class="nav-link" href="{{telemexLink}}/inscription1.php">Nous rejoindre</a>
      <a class="nav-link" href="https://suivi.telemexcm.com/user" >suivi</a>
      <a class="nav-link"  href="{{telemexLink}}/historique-new.php">Historique</a>
      <!-- <button *ngIf="!isAuth" class="connect-btn">Se connecter <i class="fas fa-sign-in-alt"></i></button> --> 
    
    <!-- dropDown -->
      <div class="dropdown">
        <button id="dropdownDelayButton" (click)="toggleDropdown()" class=" bg-transparent rounded-0 border-0 text-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="contact-icon fas fa-user"></i>
        </button>
      
        <ul class="dropdown-menu dropdown-menu-end show dropdown-menu-lg-right" [ngClass]="{'show': isOpen}" aria-labelledby="dropdownDelayButton" style="background-color: #2B2E86;">
        
          <li class="dropdown-item">
            <a class="nav-link" href="{{telemexLink}}/profile.php">
              <span class="">Profile</span>
            </a>
          </li>
          <li class="dropdown-item">
            <a href="{{telemexLink}}/logout.php" class="nav-link">
              <span>Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
      
      
      
     <!--  <a class="nav-link" href="http://localhost/telemex/profile.php">
      </a> -->
    </div>







<div class="toggle-sidebar" >

    <button  style="color: #fff; background-color: transparent; border: none;" class="menu mr-3 " (click)="toggleDrawer()" >
      <i style="color: #fff; font-size: 1.2rem;" class="fas fa-bars "></i>
    </button>

    <a class="profile text-white" href="{{telemexLink}}/profile.php">
      <i class="contact-icon fas fa-user " > </i>
    </a>

  </div>

    <div class="menu-small" #drawer>
      <div style="display: flex; align-items: center; justify-content: end; gap: 20px;  margin-bottom: 50px;">
       <!--  <button
        style="color: #fff; background-color: transparent; border: none;">
          <i class="fas fa-user" style="color: #fff; font-size: 1.2rem;"></i>
        </button> -->
        <button  (click)="toggleDrawer()" type="button" class="close" aria-label="Close"  style="color: #fff; background-color: transparent; border: none;">
          <span aria-hidden="true"><i class="fas fa-times"  style="color: #fff; font-size: 1.5rem;"></i></span>
        </button>
      </div>

      <a class="nav-link text-white " href="{{telemexLink}}/profile.php">
        <i class="contact-icon fas fa-user " > </i>
        <span style="margin-left: 20px;" >Profile</span>
      </a>

      <a class="nav-link"  href="{{telemexLink}}/chargement-new.php" >
        <svg xmlns="http://www.w3.org/2000/svg" style="font-size: 10px; margin-right:20px;" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46zM6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4zM3 6v9h.76c.55-.61 1.35-1 2.24-1s1.69.39 2.24 1H15V6zm7 1l3.5 3.5L10 14v-2.5H5v-2h5z"></path></svg>  
      
      <span>envoyer une colis</span>  
      </a>


      <a href="{{telemexLink}}/inscription1.php" style="margin-left:15px; color:#fff" >
        <svg xmlns="http://www.w3.org/2000/svg" style="font-size: 15px; margin-right:20px;" width="1em" height="1em" viewBox="0 0 2048 2048"><path fill="currentColor" d="M918 1881q-10 38-15 77t-7 80q-128-15-244-60t-215-115t-180-162t-138-200t-88-228t-31-249q0-141 36-272t103-245t160-207t208-160T751 37t273-37q141 0 271 36t245 104t207 160t161 208t103 244t37 272h-71q-14-23-29-45t-35-42q-6-87-31-169H657q-8 64-12 127t-5 129q0 65 4 128t13 128h367q0 32 5 64t13 64H679q12 57 32 125t50 133t69 122t88 93m915-1241q-39-81-92-152t-120-130t-142-105t-162-75q36 50 64 106t51 115t39 120t28 121zm-809-512q-50 0-92 27t-78 71t-64 99t-51 113t-37 110t-23 92h690q-8-39-23-92t-37-110t-50-112t-64-100t-79-71t-92-27m-292 49q-85 29-162 74T427 356T307 487t-92 153h334q11-55 27-117t40-124t52-119t64-103m-604 847q0 133 37 256h363q-8-64-12-127t-4-129q0-65 4-128t12-128H165q-37 123-37 256m85 384q39 81 92 152t120 131t144 104t162 75q-36-50-64-106t-51-115t-39-120t-29-121zm1549 181q65 33 118 81t90 108t58 128t20 142h-128q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149h-128q0-73 20-141t57-129t90-108t118-81q-75-54-116-135t-41-174q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-117 136m-226-53q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20"></path></svg>
         Nous Rejoindre
      </a>

      <a  class="nav-link" href="https://suivi.telemexcm.com/user" >
        <i class="fas fa-map-marker-alt" style="margin-right: 20px;"></i> Suivi

      </a>
      <a  class="nav-link"  href="{{telemexLink}}/historique-new.php">
        <i class="fas fa-history" style="margin-right: 20px;"></i> Historique
      </a>

      <a href="{{telemexLink}}/logout.php" class=" text-center " style="
      background-color: #F39F5A;
       border-radius:20%;
       padding:10px;
       margin:auto;
      ">
        <p class="text-white" >Déconnexion</p>
      </a>
      <!-- <button *ngIf="!isAuth" class="connect-btn">Se connecter <i class="fas fa-sign-in-alt"></i></button> -->

   <!--    <a  class="nav-link" href="">
        <i class="contact-icon fas fa-user " > </i>

      </a> -->

    </div>

  </div>


</nav>
<nav class="nav-small">

</nav>
