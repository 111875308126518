import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { Tile as TileLayer, Vector, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Icon, Stroke, Style } from 'ol/style';
import OSM from 'ol/source/OSM';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service ';
import { ActivatedRoute } from '@angular/router';
import XYZ from 'ol/source/XYZ';
import { async } from '@angular/core/testing';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';




const key = '5KdkENFJupPxNvDavJA8';
const telemexLink = environment.telemexLink;
const img = require('../../../../assets/delivery-motorcycle.png')
const greenDot = require('../../../../assets/dotgreen1.png');
const redDot = require('../../../../assets/dotred1.png');

const attributions =
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';


  
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @ViewChild('searchButton') searchButton: ElementRef;

  map;
  youtubeUrl = 'https://www.youtube.com/watch?v=WQFjZymnF3M';
  youtubeVideoId = 'WQFjZymnF3M';
  chicago;
  vectorSource;
  vectorLayer;
  rasterLayer;
  london: any;
  madrid: any;
  value = "";
  searchId = null;
  details = null;
  visible = true;
  feature:Feature;
  feature1:Feature;
  feature2:Feature;
  details1:any;

  constructor(private userService: UserService,private elementRef :ElementRef , private route: ActivatedRoute,
    private notifyService: NotificationService,private ngxService: NgxUiLoaderService
  ) {

    if (this.route.snapshot.params.id) {
      this.searchId = this.route.snapshot.params.id;
      this.value = this.searchId;

    }
    else
      console.log("ok")

  }

  ngOnInit() {
    const tag = document.createElement('script');
    const container = document.getElementById('popup'); 
    container.hidden = true;
  
    this.ngxService.start();
    this.userService.getUserByEmailPassword("telemex", "telemex2023").subscribe(
      async value => {
        
        this.ngxService.stop();
        const id = this.route.snapshot.queryParamMap.get('id');



        if (value.hasOwnProperty('id')) {
          this.notifyService.showSuccess("Bienvenue!", "TELEFRET")
          this.userService.addItem(JSON.stringify(value));
          this.userService.user = value;
          this.userService.isAuth = true;
          // alert(this.route.snapshot.params.id)
          if (this.route.snapshot.params.id == "all") {
            this.onChangeall(true); this.onChangeall(false);
          } else
          if (id) {
            this.value = id;
            this.onChange(this.value,true); this.onChange(this.value,false);
          }
        }
        else {
          this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET");
          return;
        }
      },
      error => {

        this.notifyService.showError("Email ou mot de passe incorrect!", "TELEFRET")
      }
    )


    


  }





  async triggerClickEvent() {

    /* const element: HTMLElement = this.elementRef.nativeElement.querySelector('#searchButton');
    if (element) {
      element.click();
    } else {
      console.error('Element not found');
    } */
  }
  initilizeMap(ListMarker, filter, direction) {
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    container.hidden = false;
 /*    console.log("ListMarkerListMarkerListMarker", ListMarker);
    console.log("filterfilterfilterfilter", filter) */
    var arrayMarker = [];
    const divmap = document.getElementById('map');
    const element = ListMarker[0];
    ListMarker.map(element => {
      if (element) {
        if (filter && filter != '' && element.idRealTimeRecord) {
          divmap.innerHTML = '<label>Loading..</label>';
          this.valueFind = true;
          /* console.log("filterrrrr", (element.idRealTimeRecord + "").includes(filter));*/
/*           console.log("*******************************************", [element.coordinate.lng, element.coordinate.lat]); */
            console.log("element from feature : ",element.coordinate.lng)
          var feature = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([element.coordinate.lng, element.coordinate.lat]))
            
          });
          
          feature.setStyle(new Style({
            image: new Icon({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: img
            })
          }));
          feature.addEventListener('click', event => {
            console.log("clicked");
            return true;
          });

           var feature1 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat(ListMarker[1].Chargement.longitude), parseFloat(ListMarker[1].Chargement.latitude)]))
          });
          feature1.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: greenDot,
            }))
          }));
          feature1.addEventListener('click', event => {
            console.log(event);
            return true;
          });
          arrayMarker.push(feature1);
          var feature2 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat(ListMarker[1].Chargement.longitude1), parseFloat(ListMarker[1].Chargement.latitude1)]))
          });
          feature2.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src:redDot,
            }))
          }));
          feature2.addEventListener('click', event => {
            return true;
          });
          arrayMarker.push(feature2); 

          arrayMarker.push(feature);
          this.feature=feature;
          this.feature1=feature1;
          this.feature2=feature2;

        }
/*         else if (filter == null || filter == '') {
 */          // var routeLayer = new Vector({
          //    style: new Style({
          //      stroke:new Stroke({ color: "hsl(205, 100%, 50%)", width: 4 })
          //    })
          //  });
        /*   var feature1 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat(ListMarker[0].Chargement.longitude), parseFloat(ListMarker[0].Chargement.latitude)]))
          });

          feature1.setStyle(new Style({
            image: new Icon(({
              scale: 2,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../assets/dotgreen.png'
            }))
          }));
          feature1.addEventListener('click', event => {
            console.log(event);
            return true;
          });
          // arrayMarker.push(feature1);
          var feature2 = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([parseFloat(ListMarker[0].Chargement.longitude1), parseFloat(ListMarker[0].Chargement.latitude1)]))
          });
          feature2.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src: '../../../../assets/dotred.png'
            }))
          }));
          feature2.addEventListener('click', event => {
            console.log('../../../../../assets/dotred.png');
            return true;
          }); */
          // arrayMarker.push(feature2);

        /*   if (feature)
            arrayMarker.push(feature);
        } */

   /*      if (!filter && !direction) {
          divmap.innerHTML = '<label>Loading..</label>';
          this.valueFind = true;
          // console.log("*******************************************", [element.coordinate.lng, element.coordinate.lat]);
          var feature = new Feature({
            type: 'click',
            geometry: new Point(fromLonLat([element.coordinate.lng, element.coordinate.lat]))
          });
          feature.setStyle(new Style({
            image: new Icon(({
              scale: 0.7,
              rotateWithView: false,
              anchor: [0.5, 1],
              opacity: 1,
              src:img
            }))
          }));
          feature.addEventListener('click', event => {
            console.log('../../../../../assets/delivery-motorcycle.png');
            return true;
          });

          arrayMarker.push(feature);
        }
 */
      }
    });

/*     console.log("arrayMarkerarrayMarkerarrayMarker", arrayMarker) */
    this.vectorSource = new VectorSource({
      features: arrayMarker
    });
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });
    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    divmap.innerHTML = null;
    this.map = new Map({
      target: 'map',
      layers: [new TileLayer({
        source: new OSM() as any
      }),
      this.vectorLayer],
      overlays: [overlay],
      view: new View({
        center: fromLonLat([ListMarker[0].coordinate.lng, ListMarker[0].coordinate.lat]),
        zoom: 7
      })
    });
    var that = this;



    this.map.on('click', function (evt) {



      var f = that.map.forEachFeatureAtPixel(evt.pixel, function (ft, layer) {

        if (ft === that.feature){
          var props=that.feature.getProperties();
          console.log("fature value : " , that.details)
          content.innerHTML =`
          <div style='
          width:100%;
          display:flex;
          justify-content:space-around;
          align-items:center;
          flex-direction:row;
          ' >
            <img src='${telemexLink}/src/Abonnement/uploadedVehiculeImage/${that.details[1].Abonnement?.img_vehicule}' style='
            width:80px;
            height:50px;
            margin-Bottom : 10px;
           
            ' />
            <div >
              <p>véhicule : ${that.details[1].Abonnement?.type_vehicule}</p>
              <p>Matricule : ${that.details[1].Abonnement?.matricule}</p>
            </div>
            
            </div>
          <p> <b>Nom de Chauffeur:</b> ${that.details[1].Abonnement?.nom_chauffeur}</p>
          <p> Téléphone :  ${that.details[1].Abonnement?.telephone}</p>
  <p> <button class="btn btn-primary" style="width: 100%;" onclick="window.location.href = 'https://www.google.com/maps/dir/My+Location/${ that.details[1]?.Chargement?.latitude},${ that.details[1]?.Chargement?.longitude}';">Itinéraire</button>

  </p>

  `;
      overlay.setPosition(props.geometry.flatCoordinates);
          return ft;
        }
        if (ft === that.feature1) {
          // Do something when the feature is clicked
          //console.log('Clicked on feature1:', that.feature1.getProperties());
          var props=that.feature1.getProperties();

          content.innerHTML =`
          <p> <b>Nom expéditeur:</b> ${that.details[1].Chargement?.name_expediteur}</p>
          <p> <b>Téléphone:</b> ${that.details[1].Chargement?.telephone_expediteur}</p>
            <p><b>Type de colis:</b> ${that.details[1]?.Chargement?.marchandise}</p>
            <p><b>Heure de ramassage:</b> ${that.details[1]?.Chargement?.date_charg}</p>
          <p> <b>Adresse d’enlèvement:</b> ${that.details[1].Chargement?.ville_charg}</p>
  <p> <button class="btn btn-primary" style="width: 100%;" onclick="window.location.href = 'https://www.google.com/maps/dir/My+Location/${ that.details[1]?.Chargement?.latitude},${ that.details[1]?.Chargement?.longitude}';">Itinéraire</button>

  </p>

  `;
    overlay.setPosition(props.geometry.flatCoordinates);
        }
        if (ft === that.feature2) {
          // Do something when the feature is clicked
          console.log('Clicked on feature2:', that.feature2.getProperties());
          console.log(that.details[1].Chargement);
           var props=that.feature2.getProperties();

          content.innerHTML = `
          <p> <b>Nom destinataire:</b> ${that.details[1].Chargement?.contact_name}</p>
          <p> <b>Téléphone:</b> ${that.details[1].Chargement?.contact_phone}</p>
            <p><b>Prix:</b> ${
              that.details[1]?.Chargement?.type_vehicule!="Moto"?(that.details[1]?.Chargement?.prix_prop!=" "?  that.details[1]?.Chargement?.prix_prop:" À négocier"):that.details[1]?.Chargement?.priceestimated
            }</p>
            <p> <b>Recommandations:</b> ${that.details[1].Chargement?.details_march}</p>

          <p> <b>Adresse de livraison:</b> ${that.details[1].Chargement?.ville_liv}</p>
  <p> <button class="btn btn-primary" style="width: 100%;" onclick="window.location.href = 'https://www.google.com/maps/dir/My+Location/${ that.details[1]?.Chargement?.latitude1},${ that.details[1]?.Chargement?.longitude1}';">Itinéraire</button>  </p>

  `;
    overlay.setPosition(props.geometry.flatCoordinates);
        }
         });



      if (f && f.get('type') == 'click') {
        var geometry = f.getGeometry();
        var coord = geometry.getCoordinates();
        const coordinate = coord;
        console.log("coordinatecoordinatecoordinatecoordinate",coordinate)
        const hdms = toStringHDMS(toLonLat(coordinate));
        var listMatch = that.findwithprecision(toLonLat(coordinate)[0] + "", toLonLat(coordinate)[1] + "", that.userService.ListMarker, 5)
        console.log("***********************************1111listMatch", listMatch);

        var value = listMatch.length == 0 ? null : listMatch[0];

        console.log("***********************************1111", value);
        if (value) {
          var city;
          var speed = value.speed;
          var date = new Date(value.recordTime);
          var dateStr = ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            ("00" + date.getDate()).slice(-2) + "/" +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
          fetch(`${telemexLink}/api_redirect2/?lon=${ toLonLat(coordinate)[0]}&lat=${toLonLat(coordinate)[1]}` )
            .then(function (response) {
              return response.json();
            }).then(function (json) {
              city = json.display_name;
              console.log("valuevaluevaluevaluevaluevaluevaluevalue", value[0]);
              content.innerHTML = `
              <div class="row">
                  <div class="col-md-6">
                      <img src="${telemexLink}src/Abonnement/uploadCNI/${value[0].Abonnement?.cni}" style="width: 100%;">
                  </div>
                  <div class="col-md-6">
                      <p> <b>Matricule:</b> ${value[0].Abonnement?.matricule}</p>
                      <p> <b>Conducteur:</b> ${value[0].Abonnement?.nom_chauffeur}</p>
                  </div>
              </div>
                    <p> <b>Téléphone:</b> ${value[0].Abonnement?.telephone_chauffeur}

                    </p>

          <p> <b>Date:</b> ${value[0].recordTime ? dateStr : "Inconnu"}

          </p>
          <p>
          <b>Vitesse: </b> ${speed}
            </p>
            <p> <b>Position:</b> ${city}

            </p>

            `;
              overlay.setPosition(coordinate);
            });
        }
        else {
          var date = new Date();
          var dateStr = ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            ("00" + date.getDate()).slice(-2) + "/" +
            date.getFullYear() + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
          fetch(`${telemexLink}/api_redirect2/?lon=${ toLonLat(coordinate)[0]}&lat=${toLonLat(coordinate)[1]}`)
            .then(function (response) {
              return response.json();
            }).then(function (json) {
              city = json.display_name;
              content.innerHTML = `

        <p> <b> Adresse:</b> ${city}</p>
        <p> <b>Date:</b> ${dateStr}</p>
          `;
              overlay.setPosition(coordinate);
            });
        }
      }
    });


 /*    this.map.on('pointermove', function (e) {
      if (e.dragging) {
        return;
      }
      var pixel = that.map.getEventPixel(e.originalEvent);
      var hit = that.map.hasFeatureAtPixel(pixel);
      console.log(that.map.getTarget());
      // that.map. .cursor = hit ? 'pointer' : '';
    });
 */
    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };
  }

  valueFind = false;
  directionTofind = null;

  requestAsync(val, toast) {
    var direction = { from: null, to: null };
    var that = this
    return new Promise((resolve, reject) => {
      if (val != "") {


        if (!toast) {
          that.initilizeMap(that.details, val, that.directionTofind);

        }
      } else if (toast)
        that.notifyService.showError("Veillez entrer une valeur!", "TELEFRET");

    });
  }
  onChange(val, toast) {
    console.log(val);
    var direction = { from: null, to: null };
    var that = this;
    if (val != "") {
      if (true) {
        console.log("output : " + val);
        console.log('object')
        that.userService.getUserMapMarks(val).subscribe(
          async value => {
            console.log("valuevaluevaluevaluevalue", value);
            await this.userService.getUserMapMarksVal(val).subscribe(
              async value1 => {
                console.log("value1value1value1value1", value1[0]);
                this.details = [value1[0], value[0]];
                console.log("this.detailsthis.detailsthis.detailsthis.details111", this.details);
                if (that.details > 0) {
                  console.log("directiondirectiondirectiondirection", direction);
                  that.initilizeMap(that.details, val, direction);
                  document.getElementById('map').style.display = 'block';
                } else {
                  that.initilizeMap(that.details, val, direction);
                  document.getElementById('map').style.display = 'block';
                }
              },
              error1 => {
                if (toast)
                  that.notifyService.showError(error1.error, "TELEFRET");
                document.getElementById('map').style.display = 'none';
                console.log(error1);
              }
            );
          },
          error => {
            if (toast)
              that.notifyService.showError("échec dans le systéme", "TELEFRET");
            document.getElementById('map').style.display = 'none';
            console.log(error);
          }
        );
      }
    } else if (toast) {
      that.notifyService.showError("Veillez entrer une valeur!", "TELEFRET");
      document.getElementById('map').style.display = 'none';
    }
  }
  
  onChangeValue(val) {
    this.value = val;
  }


  onChangeall(toast) {
    var that = this
  }

  reverseGeocode(coords) {
    fetch('http://nominatim.openstreetmap.org/reverse?format=json&lon=' + coords[0] + '&lat=' + coords[1])
      .then(function (response) {
        return response.json();
      }).then(function (json) {
        console.log(json);
      });
  }


  findwithprecision(c1, c2, list, precision = 5) {

    var fil = list.filter(x => {
      return ((x.coordinate.lng + "").substr(0, precision) == c1.substr(0, precision)) && ((x.coordinate.lat + "").substr(0, precision) == c2.substr(0, precision));
    });
    if (fil.length > 1)
      this.findwithprecision(c1, c2, list, precision + 1)

    else if (fil.length == 1)
      return fil;

  }

  /* thisatob(a) {

    return a;
  } */

/*   public getPackage(id : number) : void {

  } */

}
