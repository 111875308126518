import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css'],
})
export class HomeHeaderComponent implements OnInit {
  @ViewChild('drawer') drawer : ElementRef
  private $unsubscribe: Subject<void> = new Subject<void>();
  public isAuth: boolean ;
  constructor(public userService: UserService, private router: Router) {
    this.getAuthStatus().subscribe();
  }
  isOpen: boolean = false;
telemexLink : string = environment.telemexLink;

  ngOnInit(): void {}

  logOut() {
    this.userService.logOut();
    this.router.navigateByUrl('/');
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  private getAuthStatus(): Observable<boolean> {
    return this.userService.getAuthStatus().pipe(
      takeUntil(this.$unsubscribe),
      map((isAuth: boolean) => {
        this.isAuth = isAuth;
        return isAuth;
      })
    );
  }

  public isDrawerOpen = true; // You can toggle this based on your logic

  public toggleDrawer() {
    const drawerElement = this.drawer.nativeElement as HTMLElement;
    this.isDrawerOpen = !this.isDrawerOpen;

    // Toggle the width based on the condition
    drawerElement.style.width = this.isDrawerOpen ? '60vw' : '0'; // Adjust width as needed
    drawerElement.style.padding = this.isDrawerOpen ? '10px' : '0'; // Adjust width as needed
  }
}
