import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './views/front/user/user.component';
import { UserLoginComponent } from './views/front/user/user-login/user-login.component';
import { UserCandidateComponent } from './views/front/user/user-candidate/user-candidate.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {GooglePlacesComponent} from './google-places.component';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './views/front/home/home.component';
import {HomeHeaderComponent} from './views/front/home/home-header/home-header.component';
import {HomeNotFoundComponent} from './views/front/home/home-not-found/home-not-found.component';
import {HomeFooterComponent} from './views/front/home/home-footer/home-footer.component';
import { HomeBuyComponent } from './views/front/home/home-buy/home-buy.component';
import {ClipboardModule} from 'ngx-clipboard';
import {UserDetailsComponent} from './views/front/user/user-details/user-details.component';
import {ConfirmationDialogComponent} from './views/front/confirmation-dialog/confirmation-dialog.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogService} from './services/confirmation-dialog.service';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { OsmViewComponent } from './views/osm-view/osm-view.component';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    UserLoginComponent,
    UserCandidateComponent,
    HomeComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    HomeNotFoundComponent,
    GooglePlacesComponent,
    HomeBuyComponent,
    UserDetailsComponent,
    ConfirmationDialogComponent,
    OsmViewComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // AdminRoutingModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule,
/*     BrowserAnimationsModule,
    ClipboardModule, NgbModule,AngularOpenlayersModule, */
  ],
  providers: [NgbActiveModal, ConfirmationDialogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
