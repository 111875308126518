import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserComponent } from './views/front/user/user.component';
import { UserLoginComponent } from './views/front/user/user-login/user-login.component';
import { UserCandidateComponent } from './views/front/user/user-candidate/user-candidate.component';
import { AuthGuardService } from './services/auth-guard.service';
import { HomeComponent } from './views/front/home/home.component';
import { HomeNotFoundComponent } from './views/front/home/home-not-found/home-not-found.component';
import { HomeBuyComponent } from './views/front/home/home-buy/home-buy.component';
import { UserDetailsComponent } from './views/front/user/user-details/user-details.component';
import { NotFoundPageComponent } from './views/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: 'user', component: UserComponent },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
