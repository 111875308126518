import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseAuthService {

  baseAddress = "http://fleet.tn/ws_rimtrack_all/";
  header = new HttpHeaders();
  baseOptions = {headers: this.header.append("Content-Type", "application/json")};

  constructor() { }
}
