<div
  class="container-user vh-100 d-flex flex-column justify-content-between"
  style="margin: auto"
>
  <div class="header-container">
    <app-home-header></app-home-header>
  </div>
  <!-- <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/ol3/3.5.0/ol.min.css" type="text/css"> -->
  <ng-template [ngIf]="visible">
    
    <div
      id="carouselExampleIndicators"
      class="carousel slide h-100"
      data-ride="carousel"
      style="
        background-image: url(https://telemex.suivi.telefret.com/assets/new/newdesign/images/slider/1.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        background-color: aqua;
        position:static;
      "
    >
      <div class="carousel-inner"></div>
      <div class="e1_598">
        <div
          class="bradcam_area bradcam_bg_1"
          [style.padding]="details && !details[0] ? ' 182px 0 102px' : '20px'"
          style="text-align: -webkit-center; padding: 20px"
        >
          <div
            class="col-md-7"
            *ngIf="false"
            style="
              background-image: linear-gradient(
                  rgb(255 255 255 / 50%),
                  rgb(255 255 255 / 50%)
                ),
                url(https://telemex.cm/assets/img/logo.png);
              background-repeat: no-repeat;
              background-position: center;
              background-color: white;
              filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
              margin: 25px;
              border-radius: 10px;
              text-align: start;
              border: 1px solid #cccccc;
              bottom: 12px;
              min-width: 280px;
              margin-left: auto !important;
              margin-right: auto !important;
            "
          >
            <div class="row">
              <div class="col-md-6">
                <p style="color: black !important">
                  Client:
                  {{
                    details[0].Chargement?.client
                      ? details[0]?.Chargement?.client
                      : ""
                  }}
                </p>
                <p style="color: black !important">
                  Téléphone:
                  {{
                    details[0].Chargement?.telephoneclient
                      ? details[0]?.Chargement?.telephoneclient
                      : ""
                  }}
                </p>
                <p style="color: black !important">
                  Adresse:
                  {{
                
                    details[0].Chargement?.adresseclient
                      ? details[0]?.Chargement?.adresseclient
                      : ""
                  }}
                </p>
              </div>
              <div class="col-md-6">
                <p style="color: black !important">Transorteur: Socopat</p>
                <p style="color: black !important">Téléphone:</p>
                <p style="color: black !important">Adresse:</p>
              </div>
            </div>
            <div class="row" style="display: flex; justify-content: center">
              <h6>Détails</h6>
            </div>
            <div class="row">
              <div class="col-md-6">
              <!--   <p style="color: black !important">
                  Type de marchandise:
                  {{
                    details[0].Chargement?.marchandise
                      ? details[0]?.Chargement?.marchandise
                      : ""
                  }}
                </p>

                <p style="color: black !important">
                  Adresse de chargement :
                  {{
                    details[0]?.Chargement?.adresse_charg +
                      "," +
                      thisatob(details[0]?.Chargement?.ville_charg)
                  }}
                </p>
                <p style="color: black !important">
                  Date de chargement: {{ details[0]?.Chargement?.date_charg }}
                </p>
              </div>
              <div class="col-md-6">
                <p style="color: black !important">
                  Adresse de livraison :
                  {{
                    details[0]?.Chargement?.adresse_liv +
                      "," +
                      thisatob(details[0]?.Chargement?.ville_liv)
                  }}
                </p> -->
                <p style="color: black !important">
                  Date de livraison: {{ details[0]?.Chargement?.date_liv }}
                </p>
              </div>
            </div>
          </div>
    <!--       <div class="row">
            <div class="col-xl-12"
            style="text-align: -webkit-center;margin-left: auto!important;margin-right: auto!important;">
            <div class="text-align-center">
              <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
                <div class="col-md-12">
                  <h5 for="" style="color: white;">Saisissez le N° d'expédition</h5>
                </div>
              </div>
              <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
                <div class="col-md-12">
                  <button type="button" (click)="onChange(value,true);onChange(value,false);" style="
                position:absolute; right: 10px
                " class="btn btn-success">
                    <i class="fa fa-search my-float"></i>
                  </button>
                  <input [ngModel]="value" (ngModelChange)="onChangeValue($event)" type="search" id="form1"
                    class="form-control" />

                </div>

              </div>
            </div>
          </div>
          </div> -->
        </div>

        <span 
        style="
        position: absolute;
    width: 100%;
    text-align: center;
    font-size: 2.5em;
    color: #e6e6e6;
    margin: 1rem;
        "
        >Saisissez le N° d'expédition</span>

        <div class="e1_282">
          <div class="input-wrapper">
            <input
              type="text"
              class="e1_282input search-input w-auto"
              [ngModel]="value"
              (ngModelChange)="onChangeValue($event)"
              type="search"
              id="form1"
              placeholder="Saisissez votre numéro de suivi"
            />
            <button
              class="search-icon"
              id="searchButton"
              type="button"
              (click)="onChange(value, true); onChange(value, false)"
              style="position: absolute; right: 7rem"
              #searchButton
 
            >
              <img src="assets/new/newdesign/images/Iconawesomsearch.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
    class="wrapper"
    style="
      width: 100%;
      position: absolute;
      top: 50%;
      margin: auto;
    "
  >
    <div
      id="map"
      style="height: 50vh; margin:auto; width:100%;"
      class="{{ !valueFind ? 'demo-area1' : 'demo-area' }} map"
    ></div>
  </div>
  </ng-template>
 

  <div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content"></div>
  </div>

  <div id="evaluation-modal" class="modal col-md-12">
    <div class="modal-content text-center notedivmodal">
      <div
        class="col-md-12"
        style="display: inherit; text-align: center; justify-content: center"
      >
        <div class="row">
          <span
            class="servicee1_364"
            style="color: #2b2e86 !important; font-size: 35px; width: unset"
            >Noter le service
            <div class="e1_328" style="position: unset !important"></div>
          </span>
        </div>
      </div>
      <div class="stars">
        <span class="star" data-value="1">&#9733;</span>
        <span class="star" data-value="2">&#9733;</span>
        <span class="star" data-value="3">&#9733;</span>
        <span class="star" data-value="4">&#9733;</span>
        <span class="star" data-value="5">&#9733;</span>
      </div>
      <div class="row col-md-12">
        <div class="col-md-6">
          <button
            id="evaluate-confirm-btn"
            class="aboute1_362"
            style="
              color: white;
              background-color: red !important;
              margin-top: 20px;
              width: unset !important;
              padding-left: 10px;
              padding-right: 10px;
              height: 40px !important;
            "
          >
            Confirm
          </button>
        </div>
        <div class="col-md-6">
          <button
            id="evaluate-cancel-btn"
            class="aboute1_362"
            style="
              color: white;
              background-color: red !important;
              margin-top: 20px;
              width: unset !important;
              padding-left: 10px;
              padding-right: 10px;
              height: 40px !important;
              float: right;
            "
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-home-footer></app-home-footer>
</div>
