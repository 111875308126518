import { Injectable } from '@angular/core';
import {User} from '../models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BaseAuthService} from './base-auth.service';
import {Router} from "@angular/router";
import {Seller} from '../models/seller';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotificationService} from './notification.service ';
import {BehaviorSubject, Observable} from 'rxjs';
import {Location} from '@angular/common';
import { runInThisContext } from 'vm';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  key = "p2pex_user_token";
  isAuth = false
  private  $isAuth : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public managerNumber = "+237695164368";
  public telemexLink = environment.telemexLink;

  user: User = null;
  baseAddress: string;
  ListMarker:Array<any>;
  // user: User = new User();

  constructor(
      public httpClient: HttpClient,
      public baseAuth: BaseAuthService,
      public router: Router,
      private notifyService : NotificationService,
      private ngxService: NgxUiLoaderService,
      private location: Location
  ) {
    this.baseAddress = baseAuth.baseAddress
  }


  // ----------------------------  POST REQUEST  -----------------------------
  // ----------------------------  POST REQUEST  -----------------------------

 public setAuthStatus(value : boolean) : void {
  this.$isAuth.next(value)
 }
 public getAuthStatus() : Observable<boolean> {
  return this.$isAuth.asObservable()
 }

  public getUserByEmailPassword(email: string, password: string){
    let data = {
      username: email,
      password: password
    };
    return this.httpClient.post<User>(`https://fleet.tn/ws_rimtrack_all_dev/signin`, data, this.baseAuth.baseOptions);
  }

  public getUserMapMarks(id=null){
    let token =JSON.parse(this.getItem()) as User ;
    let header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    let baseOptions = {headers:     header.append('Authorization', token.token)
  }; return this.httpClient.get(`${this.telemexLink}/api_redirect2/?id=${id?id:''}&token=${this.user.token}`, baseOptions);
  }

  public getUserMapMarksVal(value){
    let token =JSON.parse(this.getItem()) as User ;
    let header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    header.append("ngrok-skip-browser-warning","any");
    let baseOptions = {headers:     header.append('Authorization', token.token)
  }; return this.httpClient.get(`https://fleet.tn/ws_rimtrack_all/realTimeRecords/`, baseOptions);
  }

  public getSingleUserMapMarks(id=null){
    let token =JSON.parse(this.getItem()) as User ;
    let header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    let baseOptions = {headers:     header.append('Authorization', token.token)
  }; return this.httpClient.get(`${this.telemexLink}/api_redirect2/?id=${id?id:''}&token=${this.user.token}`, baseOptions);
  }

  public getAllVehicules(){
 return this.httpClient.get(`${this.telemexLink}/api_redirect2/?allvehicules=all`);
  }

  public postUser(user: User){
    return this.httpClient.post<User>(`${this.baseAddress}?f=postUser`,  user, this.baseAuth.baseOptions);
  }


  // // ----------------------------  PUT REQUEST  -----------------------------
  // // ----------------------------  PUT REQUEST  -----------------------------

  // public putUser(user: User){
  //   user.Id = +user.Id;
  //   let data = {
  //     User: JSON.stringify(user)
  //   };
  //   return this.httpClient.post<User>(`${this.baseAddress}?f=putUser&id=${user.Id}`, data, this.baseAuth.baseOptions);
  // }


  //  ---------------------------- section token ------------------------------------------------

  logOut() {
    this.deleteItem();
    this.user = null;
    this.isAuth = false;
  }

  addItem(item: string, key = this.key) {
    localStorage.setItem(key, item);
  }

  getItem(key = this.key) {
    return localStorage.getItem(key)
  }

  deleteItem(key = this.key) {
    localStorage.removeItem(key);
  }
manager;
  raw(qrInfo, res?): Observable<string> {
    /** Returns the freshly created Observable **/
    return Observable.create(function(observer) {
      /** Call your method with the callback **/
      this.manager.getData(res.width, (res1) => {
        /** Emit the callback response to the Observable **/
        // observer.next(of<string>res1)
        /** Complete the Observable to close it **/
        // observer.complete();
      });
    });
  }

  checkIfUserExist(){
        let token =JSON.parse(this.getItem()) ;
      
        }
  

}
